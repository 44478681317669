.app-dashboard{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
  background: rgba(128,200,255,1);
  background: -moz-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128,200,255,1)), color-stop(100%, rgba(180,255,0,0.29)));
  background: -webkit-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -o-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -ms-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: linear-gradient(to bottom, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80c8ff', endColorstr='#b4ff00', GradientType=0 );
}
/* .tool-pg{position: absolute; top: 0px;} */
.app-dashboard-cntr{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-image: url("/images/tree-fruits.svg");
  background-repeat: no-repeat;
  background-position-x: -17.75vh;
  background-position-y: center;
  background-size: auto 85vh;
  /* background-size:cover; */
}

.salutation{
  /* padding: 1.25rem; */
  font-size: 1rem;
  line-height: 2;
}

.call-to-action-cntr{
  padding-right: 5rem;
  align-self: flex-end;
  width: calc(46% + 5rem);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;
}

.dashboard-text{
  margin-bottom: 1.25rem;
}