@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');
html{
  line-height: 1.42857;
  color: #333;
  background-color: #fff;
}

*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Noto Sans', sans-serif;
  border: 0px;
}

a{text-decoration: none; color: inherit}

#root{
  height: 100vh;
  width: 100vw;
  overflow: auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* box-shadow: 0 1px 0 rgba(12, 13, 14, 0.1), 0 1px 3px rgba(12, 13, 14, 0.1),
    0 4px 20px rgba(12, 13, 14, 0.035), 0 1px 1px rgba(12, 13, 14, 0.025); */

  /* background: rgba(128,200,255,1);
  background: -moz-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128,200,255,1)), color-stop(100%, rgba(180,255,0,0.29)));
  background: -webkit-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -o-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -ms-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: linear-gradient(to bottom, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80c8ff', endColorstr='#b4ff00', GradientType=0 ); */

background: rgba(255,188,128,0.26);
background: -moz-linear-gradient(top, rgba(255,188,128,0.26) 0%, rgba(255,230,0,0.26) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,188,128,0.26)), color-stop(100%, rgba(255,230,0,0.26)));
background: -webkit-linear-gradient(top, rgba(255,188,128,0.26) 0%, rgba(255,230,0,0.26) 100%);
background: -o-linear-gradient(top, rgba(255,188,128,0.26) 0%, rgba(255,230,0,0.26) 100%);
background: -ms-linear-gradient(top, rgba(255,188,128,0.26) 0%, rgba(255,230,0,0.26) 100%);
background: linear-gradient(to bottom, rgba(255,188,128,0.26) 0%, rgba(255,230,0,0.26) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbc80', endColorstr='#ffe600', GradientType=0 );
}

.noto-sans{font-family: 'Noto Sans', sans-serif;}
.section-title{
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
}

.section-sub-title{
  font-size: 1rem;
  font-weight: 700;
}
.brand-footer{
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-width: 500px;
}

.btn{
  background-color: rgba(255, 106, 7, 1) !important;
  color: #fff !important;
  /* height: 2.5rem !important; */
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1.7rem;
  color: #fff;
  border-radius: 3rem;
  justify-content: center;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  /* margin-top: 1.75rem; */
  margin-bottom: 0.75rem;
  font-weight: 700;
}

.btn.bottom{
  /* width: 100%; */
  position: fixed;
  bottom: 0px; left: 0px; right: 0px;
  border-radius: 0px;
  border: 1px solid rgba(255, 106, 7, 1);
  margin-top: 0.75rem;
  margin-bottom: 0rem;
  padding: 1rem 1rem;
  justify-content: space-between;
  max-width: 500px;
  margin: auto;
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

/* If the screen size is 600px or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}