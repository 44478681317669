.tool-start{width: 120px;}
.tool-pg-cntr {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
}

.close-btn {
  align-self: flex-end;
}

.close-btn-top{
  /* position: absolute !important; */
  /* right: 6px; */
  top: -7px;
}
.section-title-text{padding-right: 1rem;}
.section-title.panel-header{
  padding-right: 8px;
}
.tool-cntr {
  font-size: 1rem;
  margin-bottom: 4rem;
}

/* Part One */
.expansion-panel {
  background-color: transparent !important;
  box-shadow: none !important;
  /* border: 1px solid #CCC; */
}
.expansion-panel-summary{
  min-height: 56px !important;
}
.expansion-panel-details{
  border-bottom: 1px solid #CCC;
}
.panel-header {
  padding: 24px;
  /* text-align: center; */
}

.expansion-panel-details-cntr {
  padding-top: 0px !important;
}
.opportunity-text-cntr{
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #dfdfcf;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem; */
}

.opportunity-text-cntr::after {
  font-family: 'Material Icons';
  content: "chevron_right";
  -webkit-font-feature-settings: 'liga' 1;
  -moz-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  /* padding-top: 4px; */
}

.opportunity {
  background-color: #ffffff;
  padding: 0.65rem 1rem;
  /* border: 1px solid #999999; */
  margin-bottom: 0.85rem;
  border-radius: 2px;
  box-shadow: 0px 3px 6px rgba(130, 130, 130, 0.16);
}
.opportunity-cntr {
  background-color: #ffffff;
  /* padding: 1rem; */
  /* border: 1px solid #999999; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 500px;
  margin: auto;
}

.opportunity-header,
.contribution-header {
  padding: 1rem 2rem;
  background-color: #f6f6f6;
}
.opportunity-body {
  padding: 1rem 2rem;
}

#notes:after,
#notes:before {
  border: none !important;
}
.add-notes,
.trigger-part-three,
.notes {
  padding: 1rem 2rem !important;
}
.notes label {
  left: initial !important;
}
.add-notes {
  width: 150px;
  margin: auto;
}

.contribution-options .contribution-type > span {
  font-family: "Noto Sans", sans-serif;
  font-size: 0.95rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: flex-start;
}

.contribution-options .contribution-type {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  margin: 0px;
}

.confirm-contribution {
  width: 120px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.part-one-submit {
  justify-content: space-between;
}
.sel-cntr {
  display: flex;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.sel-text {
  width: calc(100% - 25px);
}
.sel-count {
  background: #ba1f00;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  font-size: 0.6rem;
}

#alert-dialog-slide-title {
  margin: 2rem auto;
}

.my-contributions {
  /* margin-left: 1.5rem; */
  margin-top: 0.75rem;
}

.sel-cntr {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.sel-cntr::before {
  margin-top: 0.15rem;
  margin-right: 0.5rem;
  width: 2rem;
  content: "";
  height: 2rem;
  background-image: url("/images/splash-selected.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.cluster-title-cntr {
  display: flex;
  align-items: center;
}
.cluster-count {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 0.75rem;
}
