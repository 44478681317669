.app-report{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  
  background: rgba(128,200,255,1);
  background: -moz-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128,200,255,1)), color-stop(100%, rgba(180,255,0,0.29)));
  background: -webkit-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -o-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -ms-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: linear-gradient(to bottom, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80c8ff', endColorstr='#b4ff00', GradientType=0 );
}
/* .tool-pg{position: absolute; top: 0px;} */
.app-report-cntr{
  display: flex;
  width: 100vw;
  height: calc(100vh - 10rem);
  flex-direction: column;
  background-image: url("/images/tree.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: auto 100%;
  /* background-size:cover; */
}
.report-page{
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-items: center
}
.more-fruits-cntr{
  text-align: center;
  font-size: 0.9rem;
}
.salutation{
  /* padding: 1.25rem; */
  font-size: 1rem;
  line-height: 2;
}

.call-to-action-cntr{
  padding-right: 5rem;
  align-self: flex-end;
  width: calc(46% + 5rem);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;
}

.fruit-count{
  fill: #fff;
  stroke-width: 3;
}
.fruit-count.joy{
  display: none;
}
.fruit{
  margin-top: 0.5rem;
  color: #BA1F00;
  font-size: 1.1rem;
}

.report-welcome-text{
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn.bottom.report-done{
  justify-content: center;
}

.fruit-svg{
  stroke-width:2;
  stroke:white;
}
.fruit-svg.inner{
  fill: blue;
}
.fruit-svg.outer{
  fill: red;
}

.joy-star{
  fill: #FFBC36;
  stroke: #FFF;
  stroke-width: 1;
  display: none;
}