@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700);

.auth-form-cntr{
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.errorMsg{
  height: 1.375em;
}
.errorMsg{
  height: 1.375em;
}
.public-dashboard{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
  background: rgba(128,200,255,1);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128,200,255,1)), color-stop(100%, rgba(180,255,0,0.29)));
  background: -webkit-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: linear-gradient(to bottom, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80c8ff', endColorstr='#b4ff00', GradientType=0 );
}
/* .tool-pg{position: absolute; top: 0px;} */
.public-dashboard-cntr{
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  background-image: url("/images/tree-fruits.svg");
  background-repeat: no-repeat;
  background-position-x: -17.75vh;
  background-position-y: center;
  background-size: auto 85vh;
  padding-left: 25vw;
  align-items: center;
  justify-content: center
}
.register-cntr, .login-cntr{
  padding: 0px 2.5rem;
}
.app-dashboard{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
  background: rgba(128,200,255,1);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128,200,255,1)), color-stop(100%, rgba(180,255,0,0.29)));
  background: -webkit-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: linear-gradient(to bottom, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80c8ff', endColorstr='#b4ff00', GradientType=0 );
}
/* .tool-pg{position: absolute; top: 0px;} */
.app-dashboard-cntr{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-image: url("/images/tree-fruits.svg");
  background-repeat: no-repeat;
  background-position-x: -17.75vh;
  background-position-y: center;
  background-size: auto 85vh;
  /* background-size:cover; */
}

.salutation{
  /* padding: 1.25rem; */
  font-size: 1rem;
  line-height: 2;
}

.call-to-action-cntr{
  padding-right: 5rem;
  align-self: flex-end;
  width: calc(46% + 5rem);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;
}

.dashboard-text{
  margin-bottom: 1.25rem;
}
.app-screen, .transition-group{
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.app-screen{
  flex-direction: column;
  justify-content: space-between;
}
.tool-start{width: 120px;}
.tool-pg-cntr {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
}

.close-btn {
  align-self: flex-end;
}

.close-btn-top{
  /* position: absolute !important; */
  /* right: 6px; */
  top: -7px;
}
.section-title-text{padding-right: 1rem;}
.section-title.panel-header{
  padding-right: 8px;
}
.tool-cntr {
  font-size: 1rem;
  margin-bottom: 4rem;
}

/* Part One */
.expansion-panel {
  background-color: transparent !important;
  box-shadow: none !important;
  /* border: 1px solid #CCC; */
}
.expansion-panel-summary{
  min-height: 56px !important;
}
.expansion-panel-details{
  border-bottom: 1px solid #CCC;
}
.panel-header {
  padding: 24px;
  /* text-align: center; */
}

.expansion-panel-details-cntr {
  padding-top: 0px !important;
}
.opportunity-text-cntr{
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #dfdfcf;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem; */
}

.opportunity-text-cntr::after {
  font-family: 'Material Icons';
  content: "chevron_right";
  -webkit-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  /* padding-top: 4px; */
}

.opportunity {
  background-color: #ffffff;
  padding: 0.65rem 1rem;
  /* border: 1px solid #999999; */
  margin-bottom: 0.85rem;
  border-radius: 2px;
  box-shadow: 0px 3px 6px rgba(130, 130, 130, 0.16);
}
.opportunity-cntr {
  background-color: #ffffff;
  /* padding: 1rem; */
  /* border: 1px solid #999999; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 500px;
  margin: auto;
}

.opportunity-header,
.contribution-header {
  padding: 1rem 2rem;
  background-color: #f6f6f6;
}
.opportunity-body {
  padding: 1rem 2rem;
}

#notes:after,
#notes:before {
  border: none !important;
}
.add-notes,
.trigger-part-three,
.notes {
  padding: 1rem 2rem !important;
}
.notes label {
  left: auto !important;
  left: initial !important;
}
.add-notes {
  width: 150px;
  margin: auto;
}

.contribution-options .contribution-type > span {
  font-family: "Noto Sans", sans-serif;
  font-size: 0.95rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: flex-start;
}

.contribution-options .contribution-type {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  margin: 0px;
}

.confirm-contribution {
  width: 120px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.part-one-submit {
  justify-content: space-between;
}
.sel-cntr {
  display: flex;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.sel-text {
  width: calc(100% - 25px);
}
.sel-count {
  background: #ba1f00;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  font-size: 0.6rem;
}

#alert-dialog-slide-title {
  margin: 2rem auto;
}

.my-contributions {
  /* margin-left: 1.5rem; */
  margin-top: 0.75rem;
}

.sel-cntr {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.sel-cntr::before {
  margin-top: 0.15rem;
  margin-right: 0.5rem;
  width: 2rem;
  content: "";
  height: 2rem;
  background-image: url("/images/splash-selected.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.cluster-title-cntr {
  display: flex;
  align-items: center;
}
.cluster-count {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 0.75rem;
}

.app-report{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  
  background: rgba(128,200,255,1);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128,200,255,1)), color-stop(100%, rgba(180,255,0,0.29)));
  background: -webkit-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: linear-gradient(to bottom, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80c8ff', endColorstr='#b4ff00', GradientType=0 );
}
/* .tool-pg{position: absolute; top: 0px;} */
.app-report-cntr{
  display: flex;
  width: 100vw;
  height: calc(100vh - 10rem);
  flex-direction: column;
  background-image: url("/images/tree.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: auto 100%;
  /* background-size:cover; */
}
.report-page{
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-items: center
}
.more-fruits-cntr{
  text-align: center;
  font-size: 0.9rem;
}
.salutation{
  /* padding: 1.25rem; */
  font-size: 1rem;
  line-height: 2;
}

.call-to-action-cntr{
  padding-right: 5rem;
  align-self: flex-end;
  width: calc(46% + 5rem);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;
}

.fruit-count{
  fill: #fff;
  stroke-width: 3;
}
.fruit-count.joy{
  display: none;
}
.fruit{
  margin-top: 0.5rem;
  color: #BA1F00;
  font-size: 1.1rem;
}

.report-welcome-text{
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn.bottom.report-done{
  justify-content: center;
}

.fruit-svg{
  stroke-width:2;
  stroke:white;
}
.fruit-svg.inner{
  fill: blue;
}
.fruit-svg.outer{
  fill: red;
}

.joy-star{
  fill: #FFBC36;
  stroke: #FFF;
  stroke-width: 1;
  display: none;
}
html{
  line-height: 1.42857;
  color: #333;
  background-color: #fff;
}

*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Noto Sans', sans-serif;
  border: 0px;
}

a{text-decoration: none; color: inherit}

#root{
  height: 100vh;
  width: 100vw;
  overflow: auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* box-shadow: 0 1px 0 rgba(12, 13, 14, 0.1), 0 1px 3px rgba(12, 13, 14, 0.1),
    0 4px 20px rgba(12, 13, 14, 0.035), 0 1px 1px rgba(12, 13, 14, 0.025); */

  /* background: rgba(128,200,255,1);
  background: -moz-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128,200,255,1)), color-stop(100%, rgba(180,255,0,0.29)));
  background: -webkit-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -o-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: -ms-linear-gradient(top, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  background: linear-gradient(to bottom, rgba(128,200,255,1) 0%, rgba(180,255,0,0.29) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80c8ff', endColorstr='#b4ff00', GradientType=0 ); */

background: rgba(255,188,128,0.26);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,188,128,0.26)), color-stop(100%, rgba(255,230,0,0.26)));
background: -webkit-linear-gradient(top, rgba(255,188,128,0.26) 0%, rgba(255,230,0,0.26) 100%);
background: linear-gradient(to bottom, rgba(255,188,128,0.26) 0%, rgba(255,230,0,0.26) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbc80', endColorstr='#ffe600', GradientType=0 );
}

.noto-sans{font-family: 'Noto Sans', sans-serif;}
.section-title{
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
}

.section-sub-title{
  font-size: 1rem;
  font-weight: 700;
}
.brand-footer{
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-width: 500px;
}

.btn{
  background-color: rgba(255, 106, 7, 1) !important;
  color: #fff !important;
  /* height: 2.5rem !important; */
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1.7rem;
  color: #fff;
  border-radius: 3rem;
  justify-content: center;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  /* margin-top: 1.75rem; */
  margin-bottom: 0.75rem;
  font-weight: 700;
}

.btn.bottom{
  /* width: 100%; */
  position: fixed;
  bottom: 0px; left: 0px; right: 0px;
  border-radius: 0px;
  border: 1px solid rgba(255, 106, 7, 1);
  margin-top: 0.75rem;
  margin-bottom: 0rem;
  padding: 1rem 1rem;
  justify-content: space-between;
  max-width: 500px;
  margin: auto;
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

/* If the screen size is 600px or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}
